:root {
  --wav-orange: #f79228;
  --wav-blue: #5776e7;
  --wav-green: #3cb457;
  --wav-gray: #5f6368;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.gird-match-height {
  height: 100%;
}
.font-semi-bold {
  font-weight: 500;
}
.font-bold {
  font-weight: bold;
}
.text-right {
  text-align: right;
}
.t-center {
  text-align: center;
}
.display-flex {
  display: flex !important;
}
.display-inline {
  display: inline !important;
}
.display-center {
  display: flex;
  justify-content: center;
}
.align-center{
  display: flex;
  align-items: center;
}
.vertical-middle{
  vertical-align: middle !important;
}
.row-flex {
  display: flex;
  flex-direction: row;
}
.float-right {
  float: right;
}
.col-flex {
  display: flex;
  flex-direction: column;
}
.flex-space-between{
  display: flex;
  justify-content: space-between;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
}
.margin-right {
  margin-right: 1rem;
}
.margin-top {
  margin-top: 1rem;
}
.margin-bottom {
  margin-bottom: 1rem;
}
.t-center {
  text-align: center;
}
.contain-fit {
  object-fit: contain;
}
.fit-content{
  width: fit-content;
}
.shadow-box {
  box-shadow: 0px 0px 1px 0px black;
}
.color-blue {
  color: var(--wav-blue);
}
.color-white{
  color: #ffffff;
}
.color-orange {
  color: var(--wav-orange);
}
.blue-btn {
  color: #fff;
  background-color: var(--wav-blue);
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  justify-content: center;
}
.orange-btn {
  color: #fff;
  background-color: var(--wav-orange);
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  justify-content: center;
}
.orange-btn:hover {
  color: var(--wav-orange);
  font-weight: bold;
  background-color: #fff;
  border: 1px solid #ccc !important;
}
.custom-green-btn{
  color: #2fa34a !important;
  background-color: #fff !important;
  border-color: #1c7430 !important;
  font-weight: 500 !important;
}
.custom-red-btn{
  color: #dc3545 !important;
  background-color: #fff !important;
  border-color: #dc3545 !important;
  font-weight: 500 !important;
}
.large-font {
  font-size: 2rem;
}
.font-size-medium {
  font-size: 1.5rem;
}
.cursor-pointer {
  cursor: pointer;
}

.colorR {
  color: #dc3545;
}

.colorG {
  color: #2fa34a;
}
.link-decor{
  text-decoration: underline;
  color: var(--wav-blue);
  cursor: pointer;
}


.error-msg{
  font-size: 3rem;
  font-weight: bold;
}
.screen-height{
  min-height: 100vh;
}
.full-height{
  min-height: -webkit-fill-available;
}

.full-width {
  width: -webkit-fill-available;
}

 .options-pane{
  display: flex;
 }
/* styles added to nullify effects of bootstrap5  */
 .nav-tabs .nav-item{
   padding: 0px;
 }
 .nav-tabs .nav-item:hover{
   background-color: inherit;
 }
 .table>:not(:first-child) {
    border-top: inherit;
 }

 .error-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
 }

 .error-code {
   color:red;
   font-size: 4rem;
   font-weight: bolder;
 }

 .error-detail {
   font-size: 3rem;
   height:12vh
 }

 .home-link {
   font-size: 1.5rem;
   color:white;
   text-decoration: none;
 }

 .home-link-btn {
  color: #fff;
  background-color: var(--wav-blue);
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  justify-content: center;
}
.home-link-btn:hover {
  color: var(--wav-blue);
  font-weight: bold;
  background-color: #fff;
  border: 1px solid #5776e7 !important;
}

/* styles added to nullify effects of bootstrap5  */

.nav-tabs .nav-item{
 padding: 0px;
}
.nav-tabs .nav-item:hover{
 background-color: inherit;
}
.table>:not(:first-child) {
  border-top: inherit;
}

 .table>thead>*>* {
  background-color: #5776e7;
  color: white;
  font-weight: 500;
 }
.auto-scroll {
  overflow: auto;
}

.form-control.is-valid, 
.was-validated .form-control:valid {
  padding: .375rem .75rem !important;
}

.width-medium {
  width: 200px;
}
.vertical-sub {
  vertical-align: sub;
}
.rdrDateRangePickerWrapper {
  border: 2px solid #eff2f7;
  border-radius: 3px;
}
.react-select__loading-indicator { 
  color: #0065f9 !important;
}
.acccordion-header-icon {
  width: 20px;
}
.icon-default {
  color: #DDD;
}
.icon-warning {
  color: #FF7518
}
.icon-success {
  color: rgb(16, 177, 1);
}