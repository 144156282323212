
.wavicleLogo{
    margin-top: 10px;
    /* max-width: 80%; */
    height: 3.5rem;
}
.orange_color{
    color: orange;
}
.seperate_space{
    padding: 1rem;
    flex:auto;
    flex-direction: row;
}
.seperate_image{
    padding: 0.5rem;
    padding-left: 1.5rem;
}
.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgb(49, 44, 44);
    border-top: 1px solid rgba(0,0,0,.125);
}
.card-footer:last-child {
    background-color: black;
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}
.container_image{
    margin-top: 8rem;
}
.gradiant_color{
    background-image: linear-gradient(rgb(15, 76, 241),#6688E3  ,rgb(129, 129, 238), rgb(48, 160, 235));
}
.login_title{
    color:white;
}
.img-fluid{
    padding: 1rem;
}
/* Wavicle time sheet design */
.boxed-page {
    position: relative;
    max-width: 1220px;
    margin: 0 auto;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.3);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,0.3);
    -o-box-shadow: 0 0 10px rgba(0,0,0,0.3);
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
.wav_login{
    font-size: 22px;
    line-height: 28px;
}
.copyright-section{
    /* padding: 15px 0; */
    font-size: 11px;
    letter-spacing: 0.5px;
    border-top: 1px solid rgba(255,255,255,.06);
}
.copyright-section p {
    font-size: 11px;
    text-transform: uppercase;
}