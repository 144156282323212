:root {
  --wav-orange: #f79228;
  --wav-blue: #5776e7;
}
.page-wrap {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 6rem;
}
.banner-wrap {
  margin-top: 7rem;
  margin-bottom: 5rem;
  margin-left: 0rem;
  margin-right: 0rem;
}
.course-summary {
  margin-top: 1rem;
}
.course-card {
  margin-top:1rem;
  height: 14rem;
}
.add-logo {
  float: right;
  width: 2.5rem;
  cursor: pointer;
  color: var(--wav-blue);
  margin-top: 0.43rem;
}
.addIcon {
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  height: 2.5rem;
}
.course-card .card-body {
  justify-content: space-between;
  padding-bottom: 0rem !important;
}
.custom-blue-btn{
  color: #0069d999 !important;
    font-weight: 500 !important;
    background-color: #f8f9fa !important;
    border-color: #ccc !important;
}
.card-img-top {
  height: 4rem;
}
.wa-menu-item {
  font-family: "Roboto", sans-serif !important;
  font-size: 1rem;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  height: 3.4rem;
  color: #696969 !important;
}
.wa-menu-item ::after {
  content: none;
}
.wa-menu-item :hover {
  color: var(--wav-orange) !important;
}
.menu-active {
  border-bottom: 4px solid var(--wav-orange);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ffffff !important;
  background-color: var(--wav-orange) !important;
  border-radius: 2rem;
}
.course-enroll-button {
  padding-right: 1rem;
  text-align: right;
  background-color: var(--wav-blue) !important;
}
.blue-btn {
  background-color: var(--wav-blue) !important;
}
.learning-btn {
  float: right;
}

.spinner_center {
  margin-left: 35rem;
  margin-top: 10rem;
}
.expertise-level {
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif !important;
  color: var(--wav-gray);
}
.card-badge {
  background-color: #696969;
  font-size: 0.7rem !important;
  color: #ffffff;
  padding: 0.3rem;
  border-radius: 0.2rem;
}
.icon {
  padding: 10px;
  min-width: 40px;
}

@media only screen and (max-width: 991px) {
  .banner-wrap {
    margin-bottom: 0rem !important;
  }
}
.course_operation {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.trash_icon {
  padding-right: 10%;
}
.paddding_right{
  padding-right: 20px;
}