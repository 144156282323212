
.heading-title{
    font-family: sans-serif;
    box-shadow: 0px 0px 4px 0px black;
    align-items: center;
    justify-content: center;
    font-size:1rem;
    padding-bottom:0.5rem;
    padding-top:0.5rem;
    background-color: var(--wav-blue);
    color: #ffffff;
    margin-bottom: 0rem !important;
}
.new-badge{
    font-size: 0.8rem;
    margin-left:0.5rem;
    background-color: var(--wav-green) !important;
}
.announcements-div{
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    justify-content: space-evenly;
}
.announcements_bar img{
    margin: 1rem;
    width: inherit;
    max-height: 17.5rem;
}
.courses-scroll-wrap{
    width:25rem;
    height: min-content;
    border: none;
    border-color: #ececec;
    border-radius: 0.4rem;
}
.courses-scroll-wrap-notrain{
    width:25rem;
    border-radius: 0.4rem;
}
.courses-scroll-wrap h4,.courses-scroll-wrap-notrain h4{
    border-radius: 0.4rem;
    border-color: #ececec;
    text-align: center;
}
.courses-scroll-wrap .scroll-body{
    background-color: #ececec;
    height:32.3rem;
    padding: 0.8rem;
    overflow-y: scroll;
}
.courses-scroll-wrap-notrain .scroll-body-notrain{
    background-color: #ececec;
    height: 33rem;
    display: flex;
    align-items: center;
    max-height: 8rem;
    justify-content: center;
}
.scroll-course-card{
    align-items: center;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin: 0.5rem 0;
    padding-top: 0.5rem;
}
.month-name{
    padding-top: 0.7rem;
    line-height: 0.1rem;
    padding-right: 25%;
}
.date-card{
    background-image: url("../assets/calendar.png");
    background-repeat: no-repeat;
    color:#000000;
    height: 7rem;
    width: 8rem;
    margin-left:0.8rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.date-text{
    display: block;
    font-size:3rem;
    padding-right: 25%;
}
.scroll-course-card .training-summary{
    width: 17rem;
}
.training-logo{
    margin-left: 1rem;
    height: 1.2rem;
    width: 1.2rem;
}
.training-summary.card-body{
    padding:0 0.85rem 0.85rem 0.85rem !important;
}
.training-summary.card-body .cart-titledesc{
    margin-bottom: 0;
}
.calender-card-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
}