.side-bar{
    background-color: #ffffff;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 98vh;
    width: 30%;
    border-right: 1px solid #8a8a8a4d;
}
.emp-mgmt-wrap{
    margin-top: 0;
    margin-left: 0;
    padding: 10px;
}

.sb-option{
    border-bottom: 1px solid #8a8a8a4d;
    width: 100%;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.sb-option:hover{
    background-color: var(--wav-blue);
    color: #ffffff;
}
.editable-cell{
    border: 1px solid #00000021;
}
.editable-dropdown{
    border: 1px solid #00000021;
    margin-right: 5px;
}
.editable-cell :focus{
    border: 1px solid #00000057;
}
.page-ctrl{
    border: 1px solid var(--wav-orange);
    width: 2rem;
    text-align: center;
}
.disabled-ctrl{
    width: 2rem;
    border: 1px solid #6163642e;
    text-align: center;
}
.custom-combo{
    border: 1px solid #00000021;
}
.pagination{
    margin-top: 1rem;
}
.buttonContainer{
    width: 100%;
     display: flex;
      flex-direction: row; 
     justify-content:space-between;
}
.tableBorder {
    border: 1px solid #d9d9d9;
}

.tableHeader {
    text-align: center;
}

.customTable {
    margin-top: 0.5rem;
    border-color: #5776e7;
}
.table-field{
    padding:5%;
}

.delete-icon-table{
    justify-content:center;
    width:100%;
    min-width:50px;
    margin-left:25%;
     cursor:pointer;
}

#pagination-input {
    width:100px;
}

.date-format {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    background-color: hsl(0, 0%, 100%);
    align-items: center;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    box-sizing: border-box;
    margin: 0 2px 0 2px;
    display: block;
    padding: 2px 0 0 10px;
    visibility: visible;
    flex-wrap: wrap;
    text-align: left;
    display: flex;
    justify-content: space-between;
    transition: border-color 150ms;
}

.root-arrow {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
    box-sizing: border-box;
}

.date-format:hover {
    border-color: hsl(0, 0%, 70%);
}
.arrow-indicator {
    color: hsl(0, 0%, 80%);
    display: flex;
    box-sizing: border-box;
    padding: 0 7px 0 7px;
    transition: color 150ms;
}

.arrow-indicator:hover {
    color: hsl(0, 0%, 60%)
}

.datepicker-padding-top {
    padding: 5px;

}
.line-indicator {
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.marginTopBottom {
    margin: 15px 0;
}

.width-date {
    display: block;
    width: 235px;
    min-width: 235px;
    max-width: 235px;
}

.datePicker {
    width: 100%;
  }

.pagination-bar {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
  }

  .filter-container {
    border-radius: 5px;
    border: 1px solid #5776e7;
    display: flex;
    flex-direction: column;
}
.collapsible-container {
    max-height: 0;
    overflow: hidden;
    padding-left: 10px;
    transition: max-height 0.3s ease-in-out;
    padding-right: 10px;
    border-radius: 0 0 4px 4px;
}

.collapsed {
    max-height: 0;
}

.expanded {
    max-height: 500px;   
}

.collapse-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0 0;
    background-color: #5776e7;
    color: white;
    padding: 6px;
}

.collapse-header-title {
    margin: 0;
}

.minW {
    min-width: 477px;
    padding-left: 14px;
}

.minW>span {
    padding-bottom: 10px;
}

.custom-table {
    min-width: 477px;
    padding-left: 14px;
}

.minW>span {
    padding-bottom: 10px;
}

.custom-table {
    margin-top: 10px;
}

.flex-format {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    justify-content: space-around;

}

.searchbox {
    position: relative;
    height: 37px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 5px;
    margin-bottom: 20px;
    grid-column: 1/ 1; 
    grid-row: 2/2;
    width: 12.5rem;
}

.width-medium {
    width: 220px;
  }

.width-medium {
    width: 220px;
  }

.icon-style {
    position: absolute;
    top: 9px;
    left: 7px;
}

.grid-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    margin-left: 60px;
    grid-row-gap: 10px
}

.search-grid {
   grid-column: 1 / 3;
}

.date-grid {
    grid-column: 3/ 3; 
    grid-row: 2/2;
}
.date-grid2 {
    grid-column: 2/ 3; 
    grid-row: 2/2;
}

.download-grid {
    margin-top: 10px;
    align-items: center;
}

.export-icon{
    margin-left: 0;
    margin-right: 3px;
    font-size: 15px;
}

.status-grid {
    margin-bottom: 10px;
}