.side-bar{
    background-color: #ffffff;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 98vh;
    width: 30%;
    border-right: 1px solid #8a8a8a4d;
}
.emp-mgmt-wrap{
    margin-top: 3rem;
    margin-left: 2rem;
}

.sb-option{
    border-bottom: 1px solid #8a8a8a4d;
    width: 100%;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.sb-option:hover{
    background-color: var(--wav-blue);
    color: #ffffff;
}
.editable-cell{
    border: 1px solid #00000021;
}
.editable-cell :focus{
    border: 1px solid #00000057;
}
.page-ctrl{
    border: 1px solid var(--wav-orange);
    width: 2rem;
    text-align: center;
}
.disabled-ctrl{
    width: 2rem;
    border: 1px solid #6163642e;
    text-align: center;
}
.custom-combo{
    border: 1px solid #00000021;
}
.pagination{
    margin-top: 1rem;
}
.buttonContainer{
    width: 100%;
     display: flex;
      flex-direction: row; 
     justify-content:space-between;
}
.tableBorder {
    border: 1px solid #d9d9d9;
}

.tableHeader {
    text-align: center;
}

.table-field{
    padding:5%;
}

.delete-icon-table{
    justify-content:center;
    width:100%;
    min-width:50px;
    margin-left:25%;
     cursor:pointer;
}

#pagination-input {
    width:100px;
}

.modal-content {
    width: -webkit-fill-available !important;
}
/* tag input  */
@import url("https://fonts.googleapis.com/css?family=Overpass");

/* * {
	box-sizing: border-box;
	font-family: "Overpass", sans-serif;
} */

/* body {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Overpass", sans-serif;
} */

/* .tags-input */
.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: suto;
    padding: 0 8px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
  }
  .tags-input:focus-within {
    border: 1px solid #0052cc;
  }
  
  /* .tags-input input */
  .tags-input input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
  }
  .tags-input input:focus {
    outline: transparent;
  }
  
  .save-as {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* #tags */
  #tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
  }
  
  /* .tag */
  .tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #0052cc;
  }
  .tag .tag-title {
    margin-top: 3px;
  }
  .tag .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

  /* suggestion */
/* Suggestion list */
.suggestion-container {
    position: relative;
    flex: 1;
    margin-left: 8px;
  }
  
  .suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px; /* Set the maximum height for the suggestion list */
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 2; /* Ensure the suggestion list appears above tags */
  }
  
  .suggestion-list li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .suggestion-list li:hover {
    background-color: #f0f0f0;
  }
  
  
  /* Media Query */
  @media screen and (max-width: 567px) {
    .tags-input {
      width: calc(100vw - 32px);
    }
  }

  .div-align-text-center {
    text-align: center;
    line-height: 50px;
  }
  