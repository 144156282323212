.login_button {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: .8%;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}

.logo {
    width:20px;
    height:20px;
    padding-right: 5%;
}

.logout_button {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-left: 10%;
    width:100%;
    cursor: pointer;
}