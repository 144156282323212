.page-wrap {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 6rem;
}

.competency-row {
  padding-top: 10px;
}

.competency-combo {
  max-width: 15rem !important;
}
.borders{
  border:1px solid #dee2e6;
  border-top: unset;
}
.tab-container {
  border:1px solid #dee2e6;
  border-top: unset;
  min-height: 45rem;
  padding: 10px;
}
.com-comb {
  max-width: 17% !important;
}

.form-sm {
  width: 25rem;
  float: right;
}

.save-btn{
  width: 4rem;
  height: 2.5rem;
  right: 100px;
  float: right;
}

.remove-padding {
  padding: 0 !important;
}

.main-container {
  max-width: 100% !important;
}

.border-panel {
  position: absolute;
  top: 9.25rem;
  width: 81rem;
  background: white;
}

.padding-addgoals {
  max-width: fit-content !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem;
  margin-right: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  align-items: center;
  font-size: medium;
  
}
.padding-addIcon {
  max-width: fit-content !important;
  margin-left: 1rem !important;
  margin-right: 0 !important;
  margin-bottom: 10px;
  position: absolute;
  padding-right: 0 !important;
  padding-left: 0 !important;
  }
.padding-course {
  max-width: fit-content !important;
  margin-left: 1rem !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.padding-minimum {
  max-width: 55% !important;
  margin-left: 2.5rem !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  /* background-color: red; */
}

.padding-minimum-label {
  max-width: fit-content !important;
  margin-left: 0 !important;
  margin-right: 4px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.padding-minimum-box {
  margin-left: 1rem !important;
  margin-right: 3rem !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.padding-weightage {
  max-width: 70% !important;
  margin-left: 2.5rem !important;
  margin-right: 20px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}


.flex-two {
  flex: 2;
}