.side-bar{
    background-color: #ffffff;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 98vh;
    width: 30%;
    border-right: 1px solid #8a8a8a4d;
}
.emp-mgmt-wrap{
    margin-top: 3rem;
    margin-left: 2rem;
    padding: 10px;
}

.sb-option{
    border-bottom: 1px solid #8a8a8a4d;
    width: 100%;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.sb-option:hover{
    background-color: var(--wav-blue);
    color: #ffffff;
}
.editable-cell{
    border: 1px solid #00000021;
}
.editable-dropdown{
    border: 1px solid #00000021;
    margin-right: 5px;
}
.editable-cell :focus{
    border: 1px solid #00000057;
}
.page-ctrl{
    border: 1px solid var(--wav-orange);
    width: 2rem;
    text-align: center;
}
.disabled-ctrl{
    width: 2rem;
    border: 1px solid #6163642e;
    text-align: center;
}
.custom-combo{
    border: 1px solid #00000021;
}
.pagination{
    margin-top: 1rem;
}
.buttonContainer{
    width: 100%;
     display: flex;
      flex-direction: row; 
     justify-content:space-between;
}
.tableBorder {
    border: 1px solid #d9d9d9;
}

.tableHeader {
    text-align: center;
}

.table-field{
    padding:5%;
}

.delete-icon-table{
    justify-content:center;
    width:100%;
    min-width:50px;
    margin-left:25%;
     cursor:pointer;
}

#pagination-input {
    width:100px;
}

.custom-emp-table thead th:nth-child(8){
    min-width: 140px;
}