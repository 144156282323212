.modal-form {
  margin-bottom: 1rem !important;
}
.input-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.form-checkbox {
  align-items: center;
  padding-left: 0;
}
.form-checkbox .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.add-logo {
  float: right;
  width: 2.5rem;
  cursor: pointer;
  color: var(--wav-blue);
  margin-top: 0.43rem;
}

.training-card-container {
  margin-top: 1rem;
}
.browbag-wizard-container {
  margin: 1rem;
}
.training-card {
  height: 18rem;
}
.select-dropdown{
   width: 25%; 
}

.table {
  margin-top: 1rem;
  min-height: 10rem;
}
.upload-questionnaires-container{
  margin-top: 2%;
}
.question_upload, #atten_upload{
  margin-top: 1rem;
   padding: "2%";
}

.training-card .card-body {
  justify-content: space-between;
  padding-bottom: 0rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.enrollment{
  display: flex;
  flex-direction: row;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #2fa34a !important;
  background-color: #2fa34a !important;
}

.custom-control-label::before {
  background-color:#dc3545!important;
  border: #dc3545 !important;
}
.course-card-title {
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.3rem;
  color: #fff;
  background-color: #6c757d;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}

.addIcon {
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  height: 2.5rem;
}

.spinner_center {
  margin-left: 35rem;
  margin-top: 5rem;
}
.buttonArrow {
  position: absolute;
  bottom: 1.5rem;
}

button.blue-btn {
  border: none;
}

button.orange-btn {
  border: none;
}

.viewEnrollBtn{
  color:#007bff !important;
  text-decoration:#007bff underline;
  border: none!important;
  background: none!important;
  box-shadow: none !important;
  margin-left: -0.7rem;
}

.viewEnroll {
  margin-top: -0.4rem;
}

.switchEnroll{
  margin-left: 0.2rem;
}

.wrappingUp {
  display: flex;
  flex-direction: columns;
  align-items: baseline;
}

.colorBlue {
  color: var(--wav-blue);
}
.no_data {
  color: orange;
  margin-top: 10rem;
}

ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
}
ul:before {
  content: "";
}
li {
  padding: 10px 5px;
  cursor: pointer;
}
li:hover {
  text-decoration: none;
  background-color: rgba(128, 128, 128, 0.2);
}
ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
}
.history-card {
  margin-left: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  margin-top: 0.5rem;
  background-color: #ececec30 !important;
  margin-bottom: 0.5rem;
  min-width: min-content;
}
.history-card:hover {
  color: var(--wav-orange);
  font-weight:bold;
  box-shadow: 2px 2px 2px 2px #727272;
}
li {
  padding: 10px 5px;
}
.background-design {
  border: 1px solid rgba(0, 0, 0, 0.125);
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}
/* -------------------------- Remove Bootstrap's validation icons----------------------- */
.was-validated .form-control:valid {
  background-image: none;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border-color: unset !important;
}

.was-validated .form-control:invalid {
  background-image: none;
}
.pre-req-card {
  width: fit-content;
}

.training_operation {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: rebeccapurple;
}

.trash_icon {
  padding-right: 10%;
}
.card-overflow-text{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width:210px;
}

.filterContainer {
  padding:2%;
  display:flex;
  justify-content:flex-end;
  width:100%;
}

.training-wizard{
  min-height: 70vh;
  justify-content: space-between;
  width: auto;
}

.modal-content{
  width: max-content;
}
.training-wizard-modal .modal-content{
  width: 100% !important;
}

#assesment-wizard{
  max-height: 62vh;
  overflow-y: scroll;
  text-align: center;
}

.submitbtn{
  width: 60px;
  justify-content: center;
  
  display: flex;
}

#chart {
  /* width: 400px;
  height: 400px; */
  border: 1px solid #c3c3c3;
  display: flex;
  flex-direction: row-reverse;
}
#chart div {
  border: none;
  width: auto;
  height: 50px;
}

.brownbagsContainer {
  padding:2%;
  display:flex;
  justify-content:flex-end;
  width:100%; 
}

.training-wizard-modal {
  width:"120%",
}

.input-box{
  max-width: 25rem;
}
.trainerName{
  margin-top: 20px;
}

.media-notes-container {
  padding: 0% 0% 0% 10%;
}

.accordion-title-container{
  display: flex;
  flex-direction: row;
  }
  .circle-badge {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background-color: #8bc34a; /* Light green color */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff; /* White text color */
  }
   
  .badge-content {
    font-size: 12px;
  }
   