.certifications-card{
    margin-bottom:1rem;
    height: 11rem;
    margin-top: 1rem;
}
.spinner_center{
    margin-left: 35rem;
    margin-top: 5rem;
}
.IncWidth{
    width: 5rem;
}

.certification_type{
    margin-left: 1rem;
}
.my-certs-list{
    margin-top: 1rem;
}
.my-cert-item{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}
.table{
    margin-top: 2rem;
}
.top-icons{
    cursor: pointer;
    margin-left:1rem;
    font-size:1.6rem;
}
.top-icons:hover{
    color:var(--wav-orange)
}
.join-line{
    font-size:3rem;
}
.fa-circle.light{
    color: #a46a3e99;
}
.fa-circle.info{
    color: #798fdf;
}
.fa-circle.error{
    color: #e33232bd;
}
.fa-circle.success{
    color: #2dc04ec4;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2rem;
    text-align: center;
    pointer-events: none;
    color:black;
}

.has-search .form-control {
    padding-left: 2.375rem;
    height: 2rem;
}

.badge-logo{
    color: #FFFFFF;
    position: relative;
    right: 1.3rem;
    bottom:0.3rem;
}

.filterContainer {
 padding:2%;
 display:flex;
 justify-content:flex-end;
 width:100%;
}

.filter-input{
    max-width: 350px;
}