.slides-div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slideshow-bullets-div {
    color:#ffffff;
    background-color: #ececec44;
    position: absolute;
    text-align: center;
    bottom: 0.5rem;
    width: -webkit-fill-available;
    text-align: center;
  }
.carousel-img{
  width: -webkit-fill-available;
  object-fit:scale-down;
}

.customCombo{
  border-color:#00000033;
}
.send-icon{
  font-size: 1.42rem;  
  padding-left: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.horizontal-padding{
  padding-left: 2rem;
  padding-right: 2rem;
}
.highlight{
  font-weight: bold;
  background-color: #ecdd0c;
}

/* 
.data-set{
font-size: 20px;

font-style: inherit;

background-color: #F2EBFF;
padding-inline: 100px;
justify-content:center  ;
width: 100%;

} */

.card{
  width: 100%;
}
.inter-content{
  width: 100%;
  padding-left: 12px;
  font-weight: 500 ;
 border-style: groove;
 border-width: 1px;
  border-radius: 6px; 
}

.donut-chart{
  padding-left: 80%;
  background-color: #ecdd0c;
  left: 10%;
  
}

/********nav**************/
.training-wizard ul.nav:before {
  content: " ";
  position: absolute;
  bottom: 1px;
  width: 100%;
  height: 1px;
  z-index: 0;
  background: #f3f3f3;
}
.training-wizard ul.nav li.step-doing a, .training-wizard ul.nav li.step-doing a small, .training-wizard ul.nav li.step-doing a span {
  color: #5776e7;
}
.training-wizard ul.nav li.step-doing a:after, .training-wizard ul.nav li.step-done a:after, .training-wizard ul.nav li a:before {
  content: " ";
  position: absolute;
  margin-top: 10px;
  display: block;
  border-radius: 50%;
  color: #000;
  color: initial;
  border: none;
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
}
.training-wizard ul.nav li.step-doing a:after, .training-wizard ul.nav li.step-done a:after {
  background: #5776e7;
  width: 10px;
  height: 10px;
  z-index: 2;
  bottom: -2px;
}
.training-wizard ul.nav li a:before {
  background: #f3f3f3;
  width: 18px;
  height: 18px;
  z-index: 1;
  bottom: -6px;
}
.training-wizard ul.nav li a, .training-wizard ul.nav li a:focus {
  color: #d7d7d7;
}

.training-wizard .nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
}
.training-wizard .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.training-wizard .nav-link {
  padding: 0.5rem 1rem;
  display: block;
  padding: 0.5rem 1rem;
}
a {
  color: #212121;
  transition: color .2s;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: initial;
}
.training-wizard ul.nav li {
  position: relative;
  padding: 0.5rem 1rem;
  text-align: center;
}
.nav li {
  cursor: pointer;
}
.training-wizard ul.nav {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: initial;
  background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
  background: initial;
  position: relative;
  border: initial;
  margin-bottom: 1.5rem;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
user agent stylesheet
ul {
  list-style-type: disc;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.training-wizard ul.nav li.step-doing a:after, .training-wizard ul.nav li.step-done a:after, .training-wizard ul.nav li a:before {
  content: " ";
  position: absolute;
  margin-top: 10px;
  display: block;
  border-radius: 50%;
  color: #000;
  color: initial;
  border: none;
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
}
.training-wizard ul.nav li a:before {
  background: #f3f3f3;
  width: 18px;
  height: 18px;
  z-index: 1;
  bottom: -6px;
}
*, :after, :before {
  box-sizing: border-box;
}
.training-wizard ul.nav li.step-doing a:after, .training-wizard ul.nav li.step-done a:after {
  background: #5776e7;
  width: 10px;
  height: 10px;
  z-index: 2;
  bottom: -2px;
}
.training-wizard ul.nav li.step-doing a:after, .training-wizard ul.nav li.step-done a:after, .training-wizard ul.nav li a:before {
  content: " ";
  position: absolute;
  margin-top: 10px;
  display: block;
  border-radius: 50%;
  color: #000;
  color: initial;
  border: none;
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
}
/* *, :after, :before {
  box-sizing: border-box;
}
body ::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px hsl(0deg 0% 100% / 50%);
}
::selection {
  background: #aabfc9;
} */
/*******************/

/* .bottom-nav-btn{
  margin-bottom: 50px;
  position: fixed;
  bottom: 0;
  width: 94%;
}

.bottom-nav-btn .blue-btn{
  position: relative;
  float: right;
} */
.bottom-nav-btn{
  /* display: flex; */
  width: 100%;
}

.videoDiv
{
  justify-content: center;
}
/* .Accordindiv{
  
  overflow-y: scroll;
} */
.wizard-container{
  max-height: 75vh;
  overflow-y: scroll;
  margin-bottom: 4rem;
}
.video-frame{
  border: none;
}

.question-style {
  display: flex;
}

.assessment-option {
  text-align: left;
}