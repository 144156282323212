:root{
    --wav-orange:#F79228;
    --wav-blue:#5776e7;
    --wav-green:#3cb457;
}
.nav-bar-styles{
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}
.ml-auto.left-menu{
    height: 2.5rem;
}
.link-style{
    text-decoration: none;
}
.link-style:hover{
    text-decoration: none;
}
.fixed-top{
    position: fixed;
    top:0;
}
.shadowed-box{
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
}
.wa-jumbo{
    padding-top:9rem !important;
    height:7rem;
}
.orange-text{
    color:var(--wav-orange);
}
.d-inline-block{
    display: inline-block;
}
.navbar-brand a{
    display: flex;
    align-items: center;
}
.navbar-brand img{
    height: 4rem;
}
.padding-all{
    padding: 0.5rem !important;
}
.paddingTo{
    padding-left: 1rem;
}
/* Google logout design  */

.google_logout{
    box-shadow: unset !important;
}
/* Navbar styles */

.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: unset !important;
}
@media only screen and (max-width: 991px){
    .ml-auto.left-menu{
        height: auto;
    }
    .navbar{
        justify-content: start !important;
    }
    .slides-div{
        height: auto !important;
    }
}